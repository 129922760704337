<template>
  <div class="introduce">
    <head-nav></head-nav>
    <about-img></about-img>
    <about-nav
      :aboutBreadcrumb="breadcrumb"
      :toback-list="breadcrumbFirst"
    ></about-nav>

    <div class="wrap">
      <h3 class="introduce-title">
        <img
          :src="$t('about.title-5')"
          alt=""
        />
      </h3>
      <div class="introduce-content">
        <div
          class="introduce-content-box"
        >
          <div>
            <div>
                 {{$t('about.about-introduce.list-content-1')}}
            </div>
            <div>
              <img
            :src=" $t('about.about-introduce.list-img-1')"
            alt=""
          >
            </div>
          </div>

        </div>
      </div>

    </div>

    <footers></footers>
  </div>
</template>

<script>

import returnImg from '@/assets/image/product/fanhui.png';
import HeadNav from './head-nav';
import AboutImg from './about-img';
import Footers from './footers';
import AboutNav from './about-nav';

export default {
  components: {
    HeadNav,
    AboutImg,
    Footers,
    AboutNav,
  },
  data() {
    return {
      breadcrumb: [
        {
          label: 'about.development',
        },
      ],
      breadcrumbFirst: [
        {
          path: '/about',
          label: 'toback',
          image: returnImg,
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.introduce {
  .wrap {
    padding: 88px 0 150px 0;

    .introduce-title {
      text-align: center;
    }

    .introduce-content {
      margin: 40px 0 30px 0;
      font-size: 14px;

      &-box {
        line-height: 30px;

        img{
          width: 100%;
        }
      }
    }
  }
}
</style>
